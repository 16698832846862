<template>
<BPCard
  dataTestId="receivables-table"
  :headerConfig="headerConfig"
  :contractMode.sync="contractMode"
  hideNonTract
>
  <DataTable
  :loading="loading"
  :headers.sync="receivableHeaders"
  :items.sync="receivables"
  :customCells="customCells"
  :actions="tableActions"
  @refresh="refreshReceivables"
  @toggle="toggleAllReceivables"
  >
      <template #prepend-search>
        <EntitySelector
        :entities="entities"
        :initialEntity.sync="selectedEntity"
        @entity-selected="entitySelected"
        class="mr-4"
        />
      </template>

      <template #actions="{item}" v-if="showingAllReceivables">
          <Icon
          icon="mdi-clipboard-outline"
          iconColor="grey"
          dataTestId="acc-receivable-ticket-button"
          :tooltipText="$t('viewTicket')"
          @icon-clicked="actionButtonPressed(item, 'ticket')"/>
          <Icon
          icon="mdi-altimeter"
          dataTestId="acc-receivable-contract-button"
          :tooltipText="$t('viewContract')"
          @icon-clicked="actionButtonPressed(item, 'contract')"/>
      </template>
      <template #actions="{item}" v-else>
        <Icon
        @icon-clicked="actionButtonPressed(item, 'tract')"
        icon="mdi-clipboard-text"
        dataTestId="acc-receivable-tract-button"
        :small=false
        :tooltipText="$t('viewReceivablesByTract')"
        />
      </template>

      <!-- Receivables By Account Custom Cells -->
      <template #total-tickets="{item}">{{
        item.ticketCount
      }}</template>
      <template #total-net-weight="{item}">{{
        tonStringFromPounds(item.totalNetWeight)
      }}</template>
      <template #total-receivable="{item}">{{
        formatMoney(item.totalReceivable)
      }}</template>
      <template #average-rate="{item}">{{
        formatMoney(item.averageRate)
      }}</template>
      <!-- All Receivables Custom Cells -->
      <template #in-weight="{item}">{{
        tonStringFromPounds(item.inWeight)
      }}</template>
      <template #out-weight="{item}">{{
        tonStringFromPounds(item.outWeight)
      }}</template>
      <template #defect-weight="{item}">{{
        tonStringFromPounds(item.defectWeight)
      }}</template>
      <template #net-weight="{item}">{{
        tonStringFromPounds(item.netWeight)
      }}</template>
      <template #pay-by-tons="{item}">{{
        item.computedAtPayBy === PayBy.Load.value ? '' : item.payByTons
      }}</template>
      <template #product="{item}">{{
        item.product ? item.product : 'Other'
      }}</template>
      <template #pay-on="{item}">
        {{payOn(item.computedAtPayOn)}}
      </template>
      <template #pay-by="{item}">
        {{payBy(item.computedAtPayBy)}}
      </template>
      <template #amount="{item}">{{
        formatMoney(item.amount)
      }}</template>
      <template #penaltyAmount="{item}">{{
        formatMoney(item.penaltyAmount)
      }}</template>
      <template #rate="{item}">
        <span>{{ formatMoney(item.computedAtRate) }}</span>
      </template>
      <template #body.append>
        <tr class="font-weight-bold">
          <td class="text-left"/>
          <td class="text-right" v-if="showingAllReceivables && contractMode?.value === ContractMode.Logs.value"/>
          <td class="text-right" v-if="showingAllReceivables"/>
          <td class="text-right">
            <span v-if="!showingAllReceivables">
              {{totalUniqueTicketCount}}
            </span>
          </td>
          <td class="text-right" v-if="!showingAllReceivables">
            {{tonStringFromPounds(totalNetWeight)}}
          </td>
          <td class="text-right">
            <span v-if="!showingAllReceivables"> {{formatMoney(totalReceivable)}} </span>
          </td>
          <td class="text-right"/>
          <td class="text-right" v-if="showingAllReceivables"/>
          <td class="text-right" v-if="showingAllReceivables">
            {{tonStringFromPounds(totalNetWeight)}}
          </td>
          <td class="text-right" v-if="showingAllReceivables"/>
          <td class="text-right" v-if="showingAllReceivables"/>
          <td class="text-right" v-if="showingAllReceivables"/>
          <td class="text-right" v-if="showingAllReceivables"/>
          <td class="text-right" v-if="showingAllReceivables">
            {{formatMoney(totalPenalty)}}
          </td>
          <td class="text-right" v-if="showingAllReceivables">
            {{formatMoney(totalReceivable)}}
          </td>

          <td class="text-right" v-if="showingAllReceivables"/>
        </tr>
      </template>
      <template #activityWithSplit="{item}">
        <Icon
        v-if="item.specialization !== TemplateSpecialization.None.value"
        :icon="TemplateSpecialization.forInt(item.specialization)?.icon"
        :tooltipText="TemplateSpecialization.forInt(item.specialization)?.name"/>
        <span>
          {{ item.activityName }}
        </span>
        <span v-if="item.computedAtSplit">
        ({{ formatPercentage(item.computedAtSplit) }})</span>
      </template>
  </DataTable>
  <Dialog :stateId="dialogId" @dialog-closing="resetDialogs">
    <ContractDetails v-if="focusedContractId" :contractId="focusedContractId"/>
    <TicketDetails v-if="focusedTicketId" :ticketId="focusedTicketId" :contractMode="contractMode"/>
    <ReceivablesByTract v-if="focusedAccountReceivable" @receivable-detail="$emit('receivable-detail')" :accountReceivable="focusedAccountReceivable" :isTDialog="true"/>
  </Dialog>
</BPCard>
</template>

<script>
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import { formatMoney, numberWithCommas, tonStringFromPounds, formatPercentage } from '@/utils/NumericMutations'
import { allReceivablesCustomCells, receivablesByAccountCustomCells } from '@/components/accounting/accounts-receivable/ReceivableCustomCells.js'
import { allReceivableHeaders, allBPReceivableHeaders, receivablesByAccountHeaders } from '@/headers/Receivable'
import { PayOn, PayBy, ContractMode, TemplateSpecialization } from '@/utils/Enumerations.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import AccountingHelpers from '@/components/accounting/AccountingHelpers.js'
export default {
  name: 'AccountsReceivable',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    EntitySelector: () => import('@/components/accounting/EntitySelector.vue'),
    ContractDetails: () => import('@/components/contract/contract-detail/ContractDetail.vue'),
    TicketDetails: () => import('@/components/ticket/ticket-details/TicketDetails.vue'),
    ReceivablesByTract: () => import('@/components/accounting/accounts-receivable/ReceivablesByTract.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    BPCard: () => import('@/components/core/BPCard.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    ContractMode,
    TemplateSpecialization,
    dialogId: 'accounts-receivable',
    loading: false,
    selectedEntity: undefined,
    showingAllReceivables: false,
    dialog: false,
    focusedTicketId: undefined,
    focusedContractId: undefined,
    focusedAccountReceivable: undefined,
    entities: [],
    contractMode: null,
    PayBy
  }),

  computed: {
    ...mapGetters('receivable', ['allReceivables']),
    receivables () {
      if (this.showingAllReceivables) {
        return this.receivablesForEntity.map(r => ({
          ...r,
          activityWithSplit: {
            name: r.activityName,
            split: r.computedAtSplit
          },
          payByTons: tonStringFromPounds(r.payByWeight, 3, r.computedAtPayBy === PayBy.MetricWeight.value)
        }))
      } else {
        return this.receivablesByAccount
      }
    },

    headerConfig () {
      switch (this.contractMode?.value) {
        case ContractMode.Logs.value: return { title: this.$t('currentReceivables'), subtitle: this.$t('receivablesSubtitle') }
        case ContractMode.Byproducts.value: return { title: this.$t('currentByProductReceivables'), subtitle: this.$t('BPReceivablesSubtitle') }
        case ContractMode.Transfers.value: return { title: this.$t('transferReceivables'), subtitle: this.$t('transferReceivablesSubtitle') }
      }

      return null
    },

    receivablesForEntity () {
      if (this.selectedEntity === undefined) { return [] }
      return this.allReceivables.filter(p => p.businessEntityId === this.selectedEntity.businessEntityId)
    },

    receivablesByAccount () {
      const receivablesByAccount = AccountingHelpers.groupAccountingItemsByAccountName(this.receivablesForEntity, 'receivables')
      receivablesByAccount.forEach(pba => {
        pba.totalReceivable = (pba.receivables.reduce((a, b) => a + b.amount, 0))
        pba.averageRate = (pba.receivables.reduce((a, b) => a + b.computedAtRate, 0) / pba.receivables.length)
        pba.totalNetWeight = (pba.receivables.reduce((a, b) => a + (b.inWeight - b.outWeight - b.defectWeight), 0))
        pba.ticketCount = AccountingHelpers.uniqueTicketCount(pba.receivables)
      })

      return receivablesByAccount
    },
    totalUniqueTicketCount () { return AccountingHelpers.uniqueTicketCount(this.receivablesForEntity) },
    totalNetWeight () { return AccountingHelpers.getUniqueNetWeight(this.receivablesForEntity) },
    totalGross () { return this.receivablesForEntity.reduce((a, b) => a + b.grossAmount, 0) },
    totalReceivable () { return this.receivablesForEntity.reduce((a, b) => a + b.amount, 0) },
    totalPenalty () { return this.receivablesForEntity.reduce((a, b) => a + b.penaltyAmount, 0) },

    receivableHeaders () {
      const accountReceivableHeader = receivablesByAccountHeaders()
      if (this.contractMode !== ContractMode.Byproducts.value) accountReceivableHeader.pop()
      const allReceivableHeader = this.contractMode.value !== ContractMode.Logs.value ? allBPReceivableHeaders() : allReceivableHeaders()
      return this.showingAllReceivables
        ? allReceivableHeader
        : accountReceivableHeader
    },

    tableActions () {
      return [
        {
          icon: this.showingAllReceivables ? 'mdi-account' : 'mdi-view-list',
          text: this.showingAllReceivables ? 'View by Account' : 'View all Receivables',
          actionName: 'toggle'
        },
        {
          icon: 'mdi-refresh',
          text: 'Refresh',
          actionName: 'refresh'
        }
      ]
    },

    customCells () {
      return this.showingAllReceivables
        ? allReceivablesCustomCells()
        : receivablesByAccountCustomCells()
    }
  },

  watch: {
    contractMode (val) {
      localStorage.setItem(LocalStorageKeys.AR_CONTRACT_MODE, JSON.stringify(val))
      this.refreshReceivables()
    }
  },

  async created () {
    const contractModeCookie = localStorage.getItem(LocalStorageKeys.AR_CONTRACT_MODE)

    if (contractModeCookie) {
      this.contractMode = JSON.parse(contractModeCookie)
    } else {
      this.contractMode = ContractMode.Logs
    }
  },

  methods: {
    ...mapActions('receivable', ['fetchReceivables']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapMutations('global', ['setRouterJump']),

    entitySelected (val) {
      this.selectedEntity = val
    },

    formatMoney,
    numberWithCommas,
    tonStringFromPounds,
    formatPercentage,
    payOn: (x) => PayOn.fromInt(x),
    payBy: (x) => PayBy.fromInt(x),

    async refreshReceivables () {
      this.loading = true
      try {
        await this.fetchReceivables(this.contractMode.value)
        this.getEntitiesFromReceivables()
      } finally {
        this.loading = false
      }
    },

    toggleAllReceivables () {
      this.showingAllReceivables = !this.showingAllReceivables
    },

    getEntitiesFromReceivables () {
      this.entities = AccountingHelpers.getUniqueEntitiesFromAccountingItems(this.allReceivables)

      if (this.entities.length > 0 && this.selectedEntity === undefined) {
        this.selectedEntity = this.entities[0]
      }
    },

    resetDialogs () {
      this.dialog = false
      this.focusedContractId = undefined
      this.focusedTicketId = undefined
      this.focusedAccountReceivable = undefined
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    actionButtonPressed (receivable, dialogType) {
      this.resetDialogs()
      switch (dialogType) {
        case 'contract': this.focusedContractId = receivable.contractId; break
        case 'ticket': this.focusedTicketId = receivable.ticketId; break
        case 'tract': this.focusedAccountReceivable = receivable; break
        default: return
      }

      this.dialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    getReceivablesByAccount () { return [] }
  }
}
</script>
